import type { User } from "~/model/user";

// type RefreshTokenResponse = {code:number, token:string, refreshToken:string}

async function fetchRefreshToken(input: RequestInfo | URL, refreshToken:string): Promise<[number, User|undefined]> {
    // const failedResponse:RefreshTokenResponse = {code:2, token:'', refreshToken:''}
    // const body = {'refreshToken': refreshToken}
    const formData = new FormData()
    formData.append('refreshToken', refreshToken)
    try {
      const response = await fetch(
        input,
        {
          method: 'POST',
          body: formData,
          // body: JSON.stringify(body),
          // headers: {
          //   'Content-Type': 'application/json; charset=UTF-8'
          // }
        }
      )
      if (response.ok && response.status == 200) {
        // const json = await response.json();
        const data = await response.json();
        if(data.code === 0){
          return [0, data.response as User]
        }else{
          return [data.reason, undefined]
        }
      }
      return [2, undefined]
    } catch (e) {
      return [2, undefined]
    }
}

export async function tryRefreshSession():Promise<boolean> {
    const userId = useCookie('userId')
    const refreshToken = localStorage.getItem('refreshToken')
    localStorage.removeItem('refreshToken')
    if(userId.value && refreshToken && refreshToken.trim() != '') {
      const [reason, user] = await fetchRefreshToken('/app/user/refresh-token', refreshToken)
      
      // reason = 0 refresh token success
      // reason = 1 token already refresh, don't need refresh at this time
      // reason = 2 failed
      if(reason == 0 && user){
        // const token = useCookie('token')
        // token.value =  response.token
        const userStore = useUserStore()
        userStore.setLoginUser(user)
        
        return true
      }else if(reason == 1){
        return true
      }
    }
    return false
}
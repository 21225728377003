import { ref } from 'vue'
import { defineStore } from 'pinia';
import type { MenuProduct, ProdCategory } from "~/model/product";
import { newUser, type User } from '~/model/user';

export const menuCategoryList:ProdCategory[]=[]
export const menuProductGroup:MenuProduct[][]=[]

export const useModalDialogStore = defineStore('modalDialogStore', () => {
    const displayDialog = ref(false)
  
    function open(): boolean {
      if (displayDialog.value) {
        return false;
      }
      displayDialog.value = true;
      return true;
    }
  
    function close() {
      displayDialog.value = false;
    }
  
    return { displayDialog, open, close }
  })

  export const useUserStore = defineStore('userStore', () => {
    const hadLogined = ref(false)
    const user = reactive(newUser())
  
    function setLoginUser(data:User) {
      Object.assign(user, data)
      localStorage.setItem('refreshToken', data.refreshToken)
      hadLogined.value=true
    }

    function setLogoutUser() {
      hadLogined.value=false
      Object.assign(user, newUser())
    }
  
    return { hadLogined, user, setLoginUser, setLogoutUser }
  })